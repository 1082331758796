import type { SortItemI } from "@/utils/getters/defaultFilters";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export function setSorting(field: string, mode: 'asc' | 'desc' | '', sorting: Array<SortItemI>) {
  const result = sorting.filter((value) => value.title !== field);
  if (mode !== "") {
    const payload = {
      title: field,
      order: mode,
    };
    result.push(payload)
  }
  return result;
}

export function getSortingIcon(mode: 'asc' | 'desc' | '') {
  switch (mode) {
    case "desc":
      return UiIconNames.Icon_SortDown;
    case "asc":
      return UiIconNames.Icon_SortUp;
    default:
      return UiIconNames.Icon_Sort;
  }
}

export function getNextSortMode(mode: 'asc' | 'desc' | '') {
  switch (mode) {
    case "desc":
      return "asc";
    case "asc":
      return "";
    case "":
      return "desc";
    case undefined:
      return "desc";
    default:
      console.log("ошибка мод", mode);
      return "desc";
  }
}
